import * as EventEmitter from "events";
import changePage from "./changePage";
import {PiralInstance} from "piral-core";
import DefaultEvents from "./DefaultEvents";

export default function createDefaultEvents(eventMgr: EventEmitter,  piralInstance: PiralInstance): void
{
  eventMgr.on(DefaultEvents.PAGE_CHANGE, changePage.bind(null, piralInstance));

  eventMgr.on(DefaultEvents.ON_CONFIG_LOADED, function() {
    console.info("Event received: Config loaded");


  // TODO temporary until we have callbacks and know exactly when pilets are ready
  // currently we simply fire pilets are ready after 5 seconds 
    window.setTimeout(function() {
      eventMgr.emit(DefaultEvents.ON_ALL_PILETS_INITIALIZED);
    }, 5000)
  })

  
  eventMgr.on(DefaultEvents.ON_ALL_PILETS_INITIALIZED, function() {
    console.info("Event received: All pilets initialized");

  });
}