import {Connector, ConnectorInstance, Datapoint, DatapointInstance} from "@olive/oli-types";

export type ConnectivityDatapoint  = Datapoint & Omit<DatapointInstance, 'type'>;
export type ConnectivityConnection = Connector | ConnectorInstance;

export type ConnectivityInfo = {
  [key: string]: ConnectivityDatapoint | ConnectivityConnection
}

export default class ConnectivityHolder {
  connections: ConnectivityInfo;

  constructor(connectivity: ConnectivityInfo)
  {
    this.connections = connectivity;
  }

  get (id: string)
  {
    return this.connections[id];
  }

  getAll ()
  {
    return this.connections;
  }

}