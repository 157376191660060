import {PiralInstance} from "piral-core";


export default function changePage(piralInstance: PiralInstance, { route }: { route: string }) {
  const searchParams = location.search;

  piralInstance?.context?.navigation?.push(route + searchParams);


}
