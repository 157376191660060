import {
  ConnectorInstance,
  DatapointInstance,
  DefaultDatapointTypes,
  EventDatapointParameters,
  InlineDatapointImpl,
  InlineDataPointInstance
} from "@olive/oli-types";
import * as EventEmitter from "events";
import {
  isConnectorInstance,
  isDatapointInstance,
  isInlineDatapointInstance
} from "../utils/connectivityUtil";
import ConnectivityHolder, { ConnectivityConnection, ConnectivityDatapoint } from "./ConnectivityHolder";
import AbstractDispatcher from "./dispatchers/AbstractDispatcher";

export default class ConnectivityManager {
  holder: ConnectivityHolder;
  eventManager: EventEmitter;
  dispatchers: { [key: string]: AbstractDispatcher };

  constructor(props: {
    holder: ConnectivityHolder;
    eventManager: EventEmitter;
  }) {
    this.holder = props.holder;
    this.eventManager = props.eventManager;
    this.dispatchers = {};
    this.setupEventListeners();
  }

  setupEventListeners() {
    const connectivitys = this.holder.getAll();
    for (let key in connectivitys) {
      const datapoint = connectivitys[key];
      // we only care about connector instances here, not about connector definitions because
      // client side events can only exist in connector instances
      if (isConnectorInstance(datapoint)) {
        if(datapoint.datapoints) {
          //TODO FBA: connector reference does not work as expected in for the connector
          const entries = datapoint.datapoints;
          for (let i=0; i < entries.length; i++) {
            let resolvedConnectivity = this.resolveItem(entries[i]);
            if(!resolvedConnectivity) {
              continue;
            }
            if(resolvedConnectivity.type === DefaultDatapointTypes.EVENT) {
              const componentInstanceID = (entries[i] as DatapointInstance).parameters?.componentInstanceID;

              const eventTopic = (
                (resolvedConnectivity as DatapointInstance).datapoint as EventDatapointParameters
              ).event.topic;
              this.eventManager.on(
                componentInstanceID + "-" + eventTopic,
                this.onEvent.bind(this, datapoint.instanceID || datapoint.id)
              );
            }

            continue;
          }
        }
        const connectorInstance = datapoint;
        const source = connectorInstance.datapoints[0];
        if (!source) {
          continue;
        }
        //@ts-ignore TODO MHA source should be possible to be of type ConnectorTask
        const sourceDatapointID = typeof source === "string" ? source : source.instanceID || source.id || source.datapoint;
        const sourceDatapoint = connectivitys[sourceDatapointID] as ConnectivityDatapoint;
        let destination;
        let destinationDatapointID;
        if (!sourceDatapoint) {
          //TODO FBA: Discuss this with olive team, we know make an event after every api call and people can register onto it
          const strippedText: string = sourceDatapointID.replace(
            /^onAfter_(.*)/,
            "$1"
          );
          const sourceDatapoint = connectivitys[strippedText] as ConnectivityDatapoint;
          if (!sourceDatapoint)
          {
            continue;
          }
          this.eventManager.on(
            sourceDatapointID,
            this.onEvent.bind(this, connectorInstance.instanceID || connectorInstance.id)
          );

          continue;
        }
        if (sourceDatapoint?.datapoint?.type === DefaultDatapointTypes.EVENT) {
          const componentInstanceID = isDatapointInstance(source) ? (source as DatapointInstance).parameters?.componentInstanceID : null;
          const eventTopic = (
            sourceDatapoint.datapoint as EventDatapointParameters
          ).event.topic;
          this.eventManager.on(
            componentInstanceID + "-" + eventTopic,
            this.onEvent.bind(this, connectorInstance.instanceID || connectorInstance.id)
          );
        }
      }
      // if ("type" in datapoint && datapoint.type === TYPE.CONNECTOR) {
      //   // @ts-ignore we know it's a connector instance because of the check before
      //   const connector = datapoint as ConnectorInstance;
      //   const source = connector.source;
      //   const sourceDatapointID =
      //     typeof source === "string" ? source : source.id;
      //   const sourceDatapoint = connectivitys[sourceDatapointID] as Datapoint;
      //   if (sourceDatapoint.datapoint.type === DefaultDatapointTypes.EVENT) {
      //     const destination = connector.destination;
      //     const destinationDatapointID = typeof destination === "string" ? destination : destination.id;
      //     const eventTopic = (sourceDatapoint as EventDatapoint).datapoint.event
      //       .topic;
      //     this.eventManager.on(
      //       eventTopic,
      //       this.onEvent.bind(this, destinationDatapointID)
      //     );
      //   }
      // }
    }
  }

  private resolveItem(item: string | DatapointInstance | InlineDataPointInstance | ConnectorInstance) {
    let id: string;
    let resolvedConnectivity: DatapointInstance | ConnectorInstance | InlineDataPointInstance;
    if(typeof (item)==='string') {
      id=item;
    }

    else {
      const isInlineDatapointInstanceResult = isInlineDatapointInstance(item);
      const isDatapointInstanceResult=isDatapointInstance(item);
      const isConnectorInstanceResult=isConnectorInstance(item);
      if(isInlineDatapointInstanceResult) {
        //resolvedConnectivity = new InlineDatapointImpl(item);
        resolvedConnectivity=item;
      }
      else if(isDatapointInstanceResult) {
        const datapoint=(item as DatapointInstance).datapoint;
        if(typeof (datapoint)==='string') {
          id=datapoint;
        }

        else {
          resolvedConnectivity=datapoint;
        }
      }
      else if(isConnectorInstanceResult) {
        // events can only be first item se we disregard the others
        const datapoint=(item as ConnectorInstance).datapoints[0];
        if(typeof (datapoint)==='string') {
          id=datapoint;
        }

        else {
          resolvedConnectivity=datapoint;
        }
      }

      else {
        console.error("Unknown item type: ",item);
      }
    }
    if(!resolvedConnectivity) {
      resolvedConnectivity=this.holder.get(id);
    }
    return resolvedConnectivity;
  }

  async onEvent(
    connectorID: string,
    params?: unknown
  ) {
    let input: any = {};
    if (params instanceof FormData) {
      input.formData = params;
    } else if (typeof params === 'object' && params !== null) {
      input = { ...params };
    }
    const connector = this.holder.get(connectorID) as ConnectorInstance;
    const connectorInput = connector.parameters || {};

    input = { ...input, ...connectorInput };

    const result = await this.invoke({
      id: connectorID,
      input,
    });
    return result;
  }

  async invoke(props: { id: string; input?: unknown; parameters?: any }) {
    for (let key in this.dispatchers) {
      const dispatcher = this.dispatchers[key];
      if (dispatcher.canDispatch({ id: props.id })) {
        return await dispatcher.dispatch(props);
      }
    }
    console.error("No dispatcher found that can dispatch " + props.id);
    throw "No dispatcher found that can dispatch " + props.id;
  }

  registerDispatcher(id: string, dispatcher: AbstractDispatcher) {
    if (this.dispatchers[id]) {
      console.warn("Dispatcher with id " + id + " already registered");
    }
    this.dispatchers[id] = dispatcher;
  }
}
