import ConnectivityHolder from "../ConnectivityHolder";
import AbstractDispatcher from "./AbstractDispatcher";
import ConnectivityManager from "../ConnectivityManager";
import {
  Datapoint,
  JSONObject,
  ConnectorInstance,
  BasicInstance,
  DefaultDatapointTypes,
  WindowMessageDatapoint
} from "@olive/oli-types";
import { BroadcastEnvelope } from "@olive/broadcast-util";
import findWindowByOrigin from "./util/getAllIframeWindows";
import getAllIframeWindows from "./util/getAllIframeWindows";

export default class WindowDispatcher extends AbstractDispatcher {
  connectivityManager: ConnectivityManager;
  origins: {
    [origin: string]: {
      origin: string,
      win: MessageEventSource
    }
  }

  constructor(
    connectivityHolder: ConnectivityHolder,
    connectivityManager: ConnectivityManager
  ) {
    super(connectivityHolder);
    this.connectivityManager = connectivityManager;
    this.origins = {};
    window.addEventListener('message', this.#onMessage.bind(this));
  }

  async #onMessage(message: MessageEvent) {
    console.log("#onMessage", message);
    const envelope = message.data as BroadcastEnvelope;

    if(this.#isRegisterOriginMessage(envelope)) {
      this.#registerOriginMessage(message);
      return;
    }

    console.log("event to dispatch", envelope);

    const allConnectivitys = this.connectivityHolder.getAll();
    if(!allConnectivitys) {
      return;
    }
    const connectors = Object.keys(allConnectivitys).map(key => allConnectivitys[key]).filter(connectivity => connectivity.type === "connectorInstance");

  
    const matchingConnectors = connectors.filter(connector => {
      const connectorInstance = connector as ConnectorInstance;
      //@ts-ignore broadcast temporary problems
      return (connectorInstance.datapoints[0] as BasicInstance).id === envelope.datapointID;
    });
    console.log("matchingConnectors", matchingConnectors);

    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
      id: connector.instanceID || connector.id,
      input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
  }

  #isRegisterOriginMessage(envelope: BroadcastEnvelope)
  {
    return envelope.topic === "oliveRegisterOrigin";
  }

  #registerOriginMessage(message: MessageEvent)
  {
    console.info("registering new window origin");
    const envelope = message.data as BroadcastEnvelope;
    const payload = envelope.payload as unknown as {id: string, origin: string};
    if(payload.origin && payload.id)
    {
      this.origins[payload.id] = {
        origin: payload.origin,
        win: message.source
      }
    }
    else
    {
      console.error("Missing origin or window in register origin message: ", payload);
    }
  }

  #getDatapoint(id: string | undefined | null): Datapoint | null {
    if (!id) {
      return null;
    }
    return this.connectivityHolder.get(id) as Datapoint;
  }

  canDispatch(props: { id: string, datapoint: Datapoint | undefined }): boolean {
    const datapoint = !props.datapoint ? this.#getDatapoint(props.id) : props.datapoint;
    if (!datapoint) {
      return false;
    }
    const type = datapoint.datapoint.type;
    return type === DefaultDatapointTypes.WINDOW_MESSAGE;
  }

  async dispatch(props: { id: string; input?: object }){
    
    const datapoint = this.#getDatapoint(props.id) as WindowMessageDatapoint;
    if (!datapoint) {
      console.warn("Datapoint not found: " + props.id);
      return null;
    }

    const origin = datapoint.datapoint.windowmessage.origin;
    const originEntry = this.origins[origin];
    if(!originEntry) {
      console.error("Tried to dispatch message to unknown origin: " + origin);
    }

    const message = datapoint.datapoint.windowmessage.topic;

    // TODO need something better to combine input and messgae
    // we can not always send the same format / schema, needs to be configurable
    const envelope = {
      message: message,
      input: props.input
    };

    //@ts-ignore
    originEntry.win.postMessage(envelope, originEntry.origin);

    return props.input;
  }

}