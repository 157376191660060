import ConnectivityHolder from "../ConnectivityHolder";
import AbstractDispatcher from "./AbstractDispatcher";
import ConnectivityManager from "../ConnectivityManager";
import {
  Datapoint,
  JSONObject,
  ConnectorInstance,
  BasicInstance,
  DefaultDatapointTypes
} from "@olive/oli-types";
import { invoke, GLOBAL_CHANNEL, BroadcastEnvelope } from "@olive/broadcast-util";

export default class BroadcastDispatcher extends AbstractDispatcher {
  connectivityManager: ConnectivityManager;
  broadcastChannel: BroadcastChannel;

  constructor(
    connectivityHolder: ConnectivityHolder,
    connectivityManager: ConnectivityManager
  ) {
    super(connectivityHolder);
    this.connectivityManager = connectivityManager;
    this.broadcastChannel = GLOBAL_CHANNEL;
    //this.broadcastChannel.onmessage = this.onBroadcast.bind(this);
  }

  async #onBroadcast(message: MessageEvent) {
    // TODO fix
    // console.log("#onBroadcast", message);
    // const envelope = message.data as BroadcastEnvelope;
    // console.log("event to dispatch", envelope);

    // const allConnectivitys = this.connectivityHolder.getAll();
    // const connectors = Object.keys(allConnectivitys).map(key => allConnectivitys[key]).filter(connectivity => connectivity.type === "connectorInstance");

    // const matchingConnectors = connectors.filter(connector => ((connector as ConnectorInstance).source as BasicInstance).id === envelope.datapointID);
    // console.log("matchingConnectors", matchingConnectors);

    // const dispatchers = matchingConnectors.map(connector => this.connectivityHolder.invoke({
    //   id: ((connector as ConnectorInstance).destination as BasicInstance).instanceID,
    //   input: envelope.payload
    // }));
    // const result = await Promise.all(dispatchers);
    // console.log("dispatched: ", result);
  }

  #getDatapoint(id: string | undefined | null): Datapoint | null {
    if (!id) {
      return null;
    }
    return this.connectivityHolder.get(id) as Datapoint;
  }

  canDispatch(props: { id: string, datapoint: Datapoint | undefined }): boolean {
    const datapoint = !props.datapoint ? this.#getDatapoint(props.id) : props.datapoint;
    if (!datapoint) {
      return false;
    }
    const type = datapoint.datapoint.type;
    return type === DefaultDatapointTypes.CLIENT_BROADCAST;
  }

  async dispatch(props: { id: string; input?: object }): Promise<BroadcastEnvelope> {
    const inputData = props.input as JSONObject;
    const message = invoke({
      topic: inputData.topic as string,
      payload: inputData.payload as JSONObject
    }, props.id);
    return message;
  }
}