import ConnectivityHolder from "../ConnectivityHolder";

export default abstract class AbstractDispatcher
{
  connectivityHolder: ConnectivityHolder;
  constructor(connectivityHolder: ConnectivityHolder)
  {
    this.connectivityHolder = connectivityHolder;
  }

  abstract dispatch(props: {id: string, input?: unknown}): Promise<unknown>

  abstract canDispatch(props: {id: string}): boolean
}