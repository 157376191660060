import AbstractDispatcher from "./AbstractDispatcher";
import { config } from "../../config";
import Environment, { ENV_VARS } from "../../Environment";

export const SERVER_DISPATCHER_ID = "server-dispatcher";

const SLEEP_BETWEEN_POLLS = 1000;

const dataLayer = config.getDataLayerUrl();

console.log("Using data layer " + dataLayer);

const dataLayerInvoke = `${dataLayer}invoke`;
const dataLayerPoll = `${dataLayer}poll`;

export default class ServerDispatcher extends AbstractDispatcher {



  async dispatch(props: { id: string; input?: any }): Promise<unknown> {
    let body;
    if (props?.input?.formData instanceof FormData) {
      body = props.input.formData;
      body.append('olive', JSON.stringify({ id: props.id, input: props.input }));
      body.append('context', Environment.get(ENV_VARS.CONFIG_ID));
    } else {
      body = JSON.stringify({
        id: props.id,

        input: props.input,
        //TODO FBA: only commented out for now, because data-layer trhows error with context
        // context: Environment.get(ENV_VARS.CONFIG_ID) 
      });
    }

    const invokeResp = await fetch(
      dataLayerInvoke,
      {
        method: "POST",
        body: body,
        headers: props.input instanceof FormData ? undefined : { 'Content-Type': 'application/json' },
      }
    );
    const invokeRespJSON = await invokeResp.json();
    if (invokeRespJSON.state !== "INVOKED") {
      console.error("Error in server dispatcher during invoking data-layer");
      return null;
    }

    const pollID = invokeRespJSON.jobID;
    console.info("pollID: ", pollID);

    await sleep(SLEEP_BETWEEN_POLLS);

    let result = null;
    while (result === null) {
      const pollResp = await fetch(
        dataLayerPoll,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            jobID: pollID,
          }),
        }
      );
      const pollRespJSON = await pollResp.json();
      console.info("poll response: ", pollRespJSON);
      if (pollRespJSON.state === "SUCCESS") {
        console.info("dispatcher returning: ", pollRespJSON.result);
        if (pollRespJSON.result?.output) {
          return pollRespJSON.result?.output;
        }
        return pollRespJSON.result;
      } else if (pollRespJSON.state === "ERROR") {
        console.error("Data layer returned error!");
        return null;
      }

      // not finished yet, sleep and try again
      await sleep(SLEEP_BETWEEN_POLLS);
    }

    return null;
  }

  canDispatch(props: { id: string }): boolean {
    // everything we can't handle with other dispatchers we send to the server data-layer to handle there
    return true;
  }
}
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
